<template>
<div>
    <div class="hq_top">
        <div class="wrap">
            <a class="pointer" @click="logo('/counselor/reservation')">
                <img class="logo2" src="@/assets/images/logo.png">
            </a>
            <!--  -->
            <ul class="gnb" v-if="auth === 'MASTER'">
                <li class="hov-menu"><a class="pointer" @click="moveCenter('/origin/consult_schedule')">
                        본원센터</a></li>
                <li class="hov-menu"><a class="pointer" @click="moveAdmin('/admin/account')" :class="boldMenu('/account')">
                        관리자계정</a></li>

                <li class="hov-menu"><a class="pointer" @click="moveAdmin('/center')" :class="boldMenu('/center')">
                        센터현황</a></li>

                <li class="hov-menu"><a class="pointer bold" @click="moveAdmin('/confirmConsult')" :class="boldMenu('/confirmConsult')">
                        상담일지</a></li>

                <li class="hov-menu"><a class="pointer" @click="moveAdmin('/confirmConvention')" :class="boldMenu('/confirmConvention')">
                        협약센터승인</a></li>

                <li class="hov-menu"><a class="pointer" @click="moveAdmin('/confirmCounselor')" :class="boldMenu('/confirmCounselor')">
                        상담사승인</a></li>
                <li class="hov-menu"><a class="pointer" @click="logout()">
                        로그아웃</a>
                </li>
            </ul>
            
            <ul class="gnb" v-else>
                <li class="hov-menu"><a class="pointer" :class="boldMenu('/reservation')" @click="movePage('/reservation')">
                        상담예약현황</a>
                </li>

                <li><a class="pointer" :class="boldMenu('/journal')" @click="movePage('/journal')">
                        상담일지관리</a>
                </li>
                <li><a class="pointer" :class="boldMenu('/customer')" @click="movePage('/customer')">
                        고객차트관리</a>
                </li>
                <!-- <li><a class="pointer" :class="boldMenu('/salary')" @click="movePage('/salary')">
                        급여내역</a>
                </li> -->

                <!-- <li><a class="pointer" @click="movePage('/statistic')" :class="boldMenu('/statistic')">
                        상담통계</a>
                </li> -->

                <!-- <li><a class="pointer" @click="movePage('/modify_info')" :class="boldMenu('/modify_info')">
                        정보수정</a>
                </li> -->
                <li class="hov-menu"><a class="pointer" @click="logout()">
                        로그아웃</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="top_info">
        <div class="wrap">
            <ul>
                <li class="photo" id="status_photo"><img src="http://cdn.carrotenglish.com/bhappy/upload/profile/profile_id_1514283345.png"></li>
                <li>금일 예약건 : <span id="stat_reser">0건</span></li>
                <li>상담진행 내담자 : <span id="stat_pro">0건</span></li>
                <li>상담종결 내담자 : <span id="stat_finish">0건</span></li>
                <li>Drop Out : <span id="stat_drop">0건</span></li>
            </ul>
            <a class="logout pointer" @click="logout()">Logout</a>
        </div>
    </div> -->
    <router-view />
</div>
</template>

<script>
export default {
    data: () => ({
        toPath: '',
        auth : localStorage.getItem('auth'),
    }),

    watch: {
        // 관리자 페이지 내의 현재 라우터 경로 감지
        // Watch에 넣어놓은 이유는 keep-alive 사용으로 페이지 진입후 재진입할때 mount가 안되기 때문
        $route(to) {
            this.toPath = to.fullPath
        },
    },

    mounted() {
        this.toPath = this.$route.fullPath
        console.log('auth : ' ,this.auth);  
    },

    methods: {

        // 모든 페이지에서 로고 클릭시 상담일정 화면으로 가도록 20210316
        logo(path) {
            sessionStorage.clear()
            if(location.href.indexOf(path) > -1) {
                this.$router.go(this.$router.currentRoute)
            } else {
                this.$router.push(path)
            }
        },

        // 현재 진입한 페이지 bold처리
        boldMenu(path) {
            if (this.toPath.includes(path)) {
                return 'bold'
            } else {
                return ''
            }
        },

        // 각 입력된 path 페이지로 이동
        movePage(path) {
            sessionStorage.clear()
            this.$router.push('/counselor' + path)
        },

        // 본원센터로
        moveCenter(path) {
            sessionStorage.clear() // 검색유지조건 삭제 20210128
            this.$router.push(path)
        },

        /**
         * admin page 이동 
         */
        moveAdmin(path){
            this.$router.push('/admin' + path)
        },

        logout() {
            this.axios.get('/api/v1/login/out')
                .then(res => {
                    alert('로그아웃 되었습니다.')
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('id')
                    sessionStorage.clear()
                    this.$router.push('/login')
                })
                .catch(err => {
                    console.log(err)
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('id')
                    sessionStorage.clear()
                    alert('세션이 만료되어 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                })
        }
    }
}
</script>

<style lang="scss" scoped>
/*
body, div, ul, li, ol, dd, dt, dd, h1, h2, h3, h4, h5, h6, p, form, fieldset, legend, select, input {margin:0; padding:0;}

body, input, textarea, select, button, table {font-family:'Malgun Gothic', "돋움", Dotum, hevetica, sans-serif; font-size:12px; line-height:1.7;} 

h1, h2, h3, h4, h5, h6, {font-size:12px; font-weight:nomal;}

a {color:#666; text-decoration:none;}

a:hover, a:active {color:#6699FF;}

li, ul, ol {list-style:none;}

img { border:0;}

textarea, input {border:1px solid #d0d0d0;}

body {margin:0; padding:0;}

td {font-family:gulim; font-size:9pt; color:#736257;}*/
.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}

.hq_top {
    padding: 0px;
}

.hq_top .wrap a img {
    padding-top: 25px;
}

.hov-menu {
    padding-top: 25px;
    padding-bottom: 32px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAOCAIAAAEWJtGdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDJFRTgwNjY4MzE1MTFFNzlBQTVGMkQ2QzUzM0M1MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDJFRTgwNjc4MzE1MTFFNzlBQTVGMkQ2QzUzM0M1MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMkVFODA2NDgzMTUxMUU3OUFBNUYyRDZDNTMzQzUyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMkVFODA2NTgzMTUxMUU3OUFBNUYyRDZDNTMzQzUyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnNCdPcAAAAgSURBVHjaYnBxcWFiYGAACCAGKA0QQDAahAECDJmNjgGE2ANFCuioZwAAAABJRU5ErkJggg==) no-repeat 15px 26px;

    ul {
        display: none;
        float: none;
        background: #fff;
    }

    &:hover {
        div {
            min-width: 1600px;
            box-sizing: border-box;
            position: absolute;
            display: block;
            padding: 0;
            padding-top: 7px;
            width: 100%;
            margin-top: 32px;
            left: 0;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            background: white;
            z-index: 1;

            ul {
                // position: absolute;
                // display: block;
                padding: 0;
                width: 100%;
                // margin-top: 40px;
                margin-left: 44%;
                width: 60%;
                display: block;
                float: left;
                padding-top: 0;

                li {
                    &:first-child {
                        border-top: none;
                    }

                    // border: 1px solid #ddd;
                    margin: 0;
                    padding: 0;
                    // width: 100%;
                    padding: 0px 10px;
                    white-space: nowrap;
                    text-align: center;
                    border-right: none;
                    border-left: none;
                    height: 47px;
                    line-height: 40px;
                    float: left;
                    color: black;
                    width: auto;
                    margin-left: 30px;
                    background: none;

                    a {
                        font-size: 18px;
                        font-weight: 200;
                        cursor: pointer;

                        strong {
                            color: black;
                        }
                    }

                }
            }
        }
    }
}
</style>
